import { NavLink } from "react-router-dom";
import React, { Component } from 'react';
import  Utils from '../../utils/utils';
import db from '../../utils/db';


class Spelers extends Component {
  state = {
    allPlayers: '',
    filteredList: '',
    playersWithStats: []
  };


  setData = async () => {
    const players = await db.getPlayers();
    const allStats = [];

    for (const player of players) {
      let stats = await db.getPlayerStats(player._id);
      stats._id = player._id;
      stats.name = player.name;
      allStats.push(stats);
    }

    this.setState({
      allPlayers: players,
      filteredList: allStats,
      playersWithStats: allStats,
    });
  }

  playersList = () => {
    const items = this.state.filteredList.map((item, i) => {  
      return(
        <li key={item._id}>
         <NavLink to={'/spelers/' + item._id} state={{id: item._id}}>
          {item.name}
  
          <div className='stats'>
            <div >Games: <span>{item.games.length}</span></div>
            <div className='wins'>Gewonnen: <span>{item.wins}</span></div>
            <div className='loses'>Verloren: <span>{item.loses}</span></div>
          </div>
         </NavLink>
        </li>
      )
    });
  
    return <ul className='borderList'>{items}</ul>
  }
  
  addPlayer = async () => {
    const playerName = document.querySelector('#playerName').value;
    const playerObjectTemp = {
      name: playerName,
      games: [],
      tournements: [],
    }

    const playerObject = await db.setPlayer(playerObjectTemp);

    this.setData();
  }

  showPopup = () => {
    const overlay = document.querySelector('.overlay');
    overlay.classList.add('show');

    document.querySelector('#playerName').focus();
  }

  hidePopup = () => {
    const overlay = document.querySelector('.overlay');
    overlay.classList.remove('show');

    const popup = document.querySelector('.popup');
    popup.querySelector('input').value = '';
  }

  handleAddPlayer = () => {
    this.addPlayer();
    this.hidePopup();
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') this.handleAddPlayer();
  }

  handleOverlayClick = (event) => {
    const { target } = event;
    
    if (target.classList.contains('popup') || target.closest('.popup')) return;

    this.hidePopup();
  }
  
  addPlayerPopup = () => {
    return (
      <div className="overlay" onClick={(event) => this.handleOverlayClick(event)}>
        <div className="popup">
          <input type="text"  id="playerName" placeholder="Naam..." onKeyDown={(event) => this.handleEnter(event)}/>
          <button onClick={() => this.handleAddPlayer()}>Save</button>
        </div>
      </div>
    );
  }

  filterPlayers = (input) => {
    const allPlayers = this.state.playersWithStats;
    const filter = allPlayers.filter(e => e.name.toLocaleUpperCase().indexOf(input.toLocaleUpperCase()) !== -1);

    this.setState({filteredList: filter});
  }

  componentDidMount() {
    const mount = async () => {
      if (!this.state.allPlayers) {
        this.setData();
      }
    }

    mount();
  }


  render() {
    return (
      <div>
        <header>
          <h1>Spelers</h1>
          <div className="utils">
            <input type="text" className="search" placeholder="Zoeken..." onKeyUp={Utils.debounce((event) => this.filterPlayers(event.target.value))} />
            <button className="add" onClick={() => this.showPopup()}>+</button>
          </div>
        </header>

        {this.state.allPlayers && <this.playersList /> }
        <this.addPlayerPopup />
      </div>
    )
  }
}

export default Spelers;