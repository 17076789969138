import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import db from '../../utils/db';
import Utils from '../../utils/utils';

import GameList from '../../components/GameList';
import Vs from './Vs';

import arrow from "../../images/icons/arrow.svg";


const SpelersOverzicht = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let id = location.pathname.match(/[^/]+\/(.+)$/)[1];

  let [player, setPlayer] = useState('');
  let [choosenPlayerID, setChoosenPlayerID] = useState('');
  let [contentBlock, setContentBlock] = useState('Games');
  let [playerList, setPlayerList] = useState('');
  let [playerStats, setPlayerStats] = useState('');

  const goBack = () => {
    navigate(-1);
  }

 const Stats = () => {
  const {wins, loses, games} = playerStats;
  let points = 0;
  let pointsAgainst = 0;

  games.forEach(game => {
    const playerData = game.data.find(e => e.player === player._id);
    const opponantData = game.data.find(e => e.player !== player._id);

    
    points = points + playerData.score;
    pointsAgainst = pointsAgainst + opponantData.score;
  });

  return (
    <div className='stats'>
      <div>
        <p> Games: </p>
        <p> Gewonnen: </p>
        <p> verloren: </p>
        <p> Punten: </p>
        <p> Punten tegen: </p>
      </div>
      <div>
        <p> {games.length} </p>
        <p> {wins} </p>
        <p> {loses} </p>
        <p> {points} </p>
        <p> {pointsAgainst} </p>
      </div>
    </div>
    )
  }

  const Popup = () => {
    const filterPlayers = (input) => {
      const htmlObjs = Array.from(document.querySelectorAll('.playerList li'));

      const allowed = htmlObjs.filter(e => e.textContent.toLocaleUpperCase().indexOf(input.toLocaleUpperCase()) !== -1);
      const notAllowed = htmlObjs.filter(e => e.textContent.toLocaleUpperCase().indexOf(input.toLocaleUpperCase()) === -1);

      allowed.forEach(e => e.classList.remove('hide'));
      notAllowed.forEach(e => e.classList.add('hide'));
    }

    const items = playerList.map((e, i) => {
      return (
        <li key={i} onClick={() => setChoosenPlayerID(e._id)}>{e.name}</li>
      )
    });

    return (
      <div className="overlay" onClick={(event) => handleOverlayClick(event)}>
        <div className="popup">
          <input placeholder='Zoeken...' className='search' type='text' onKeyUp={Utils.debounce((event) => filterPlayers(event.target.value))} />

          <ul className='playerList' key={playerList}>
            {items}
          </ul>
        </div>
      </div>
    )
  }

  const hidePopup = () => {
    const overlay = document.querySelector('.overlay');
    overlay.classList.remove('show');
  }

  const handleOverlayClick = (event) => {
    const { target } = event;
    if (target.classList.contains('popup') || target.closest('.popup')) return;

    hidePopup();
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!player) {
        const players = await db.getPlayers();
        const opponants = await db.getOpponents(id);
        const stats = await db.getPlayerStats(id);
  
        setPlayer(players.find(e => e._id === id));
        setPlayerList(opponants);
        setPlayerStats(stats);
      } 
    }

    fetchData();
  },[player, id]);

  return (
    <>
    <div className='playerScreen'>
      <div className='utils'>
        <button onClick={() => goBack()} className='back'>
          <img src={arrow} title="Back" alt="back arrow" />
        </button>
      </div>
      <h1>{player.name}</h1>
      {playerStats && <Stats />}

      <div className='contentBlock'>
        <div className='contentBlock-buttons'>
          <button onClick={() => setContentBlock('Games')} className={contentBlock === 'Games' ? 'active' : ''}>Games</button>
          <button onClick={() => setContentBlock('Vs')} className={contentBlock === 'Vs' ? 'active' : ''}>Vs.</button>
        </div>

        <div className='contentBlock-inner'>
          {contentBlock === 'Games' && <GameList state={{ id }} />}
          {contentBlock === 'Vs' && <Vs state={{ id, choosenPlayerID }} key={choosenPlayerID}/>}
        </div>
      </div>
    </div>
    {playerList && <Popup />}
    </>
   )
}

export default SpelersOverzicht;