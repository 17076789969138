import React, {useState, useEffect} from 'react';
import db from '../../utils/db';

const Vs = ({state}) => {
  const { id, choosenPlayerID } = state;

  const [comparedPlayers, setComparedPlayers] = useState([]);
  const [mostWon, setMostWon] = useState({});
  const [mostLost, setMostLost] = useState({});
  const [choosenPlayer, setChoosenPlayer] = useState('');
  
 const comparePlayers = (gameList, playersList) => {
    const tempCompare = [];

    gameList.forEach(game => {
      const {data} = game;
      const opponent = data.find(e => e.player !== id);
  
      const foundOpponent = tempCompare.find(e => e.id === opponent.player);
  
      if (foundOpponent) {
        game.winner === id ?  foundOpponent.won++ : foundOpponent.lost++;
      } else {
        const opponentData = playersList.find(e => e._id === opponent.player);
        const temp = {
          id: opponentData._id,
          name: opponentData.name,
          won: 0,
          lost: 0
        }
  
        game.winner === id ? temp.won++ : temp.lost++;
        tempCompare.push(temp);
      }
    });
  
    setComparedPlayers(tempCompare);
  
    if (!tempCompare.length) return;
  
    setMostWon(
      tempCompare.reduce((prev, current) => (prev.won > current.won) ? prev : current)
    );

    setMostLost(
      tempCompare.reduce((prev, current) => (prev.lost > current.lost) ? prev : current)
    );

    if (choosenPlayerID) {
      setChoosenPlayer(tempCompare.find(e => e.id === choosenPlayerID));
    }
 }


  const showPopup = () => {
    const overlay = document.querySelector('.overlay');
    overlay.classList.add('show');
  }

  useEffect(() => {
    const fetchData = async () => {
      if (comparedPlayers.length) return;
      const getPlayers = await db.getPlayers();
      const playerStats = await db.getPlayerStats(id);

      comparePlayers(playerStats.games, getPlayers);
    }

    fetchData();
  },[]);


  return (
    
    <div className='vs'>
    {comparedPlayers.length && 
      <>
      <div className='block'>
        <h2>Meest van gewonnen</h2>

        <div className='block-inner'>
          <p className='block-name'>{mostWon.name}</p>
          <p>Games: <span>{mostWon.won + mostWon.lost}</span></p>
          <p>Won: <span>{mostWon.won}</span></p>
          <p>Lost: <span>{mostWon.lost}</span></p>
        </div>
      </div>

      <div className='block'>
        <h2>Meest van Verloren</h2>

        <div className='block-inner'>
          <p className='block-name'>{mostLost.name}</p>
          <p>Games: <span>{mostLost.won + mostLost.lost}</span></p>
          <p>Won: <span>{mostLost.won}</span></p>
          <p>Lost: <span>{mostLost.lost}</span></p>
        </div>
      </div>

      <div className='compare'>
        <div className='block'>
          <h2>Vergelijk speler</h2>

          <div className='block-inner'>
          <p className='block-name'>{choosenPlayer ? choosenPlayer.name : 'Naam'}</p>
          <p>Games: <span>{choosenPlayer ? (choosenPlayer.won + choosenPlayer.lost) : 0}</span></p>
          <p>Won: <span>{choosenPlayer ? choosenPlayer.won : 0}</span></p>
          <p>Lost: <span>{choosenPlayer ? choosenPlayer.lost : 0}</span></p>
        </div>
        </div>

        <div className='block'>
          <button className='compareButton' onClick={() => showPopup()}>Kies speler</button>
        </div>
      </div>
      </>
    }
    </div>
  )
}

export default Vs;