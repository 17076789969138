import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import db from '../utils/db';


const GameInfo = ({close}) => {
  let navigate = useNavigate();

  const [hash] = useState(() => window.location.hash);
  const [allPlayers, setAllPlayers] = useState('');
  const [playerSearch, setPlayerSearch] = useState('');
  const [filteredPlayers, setFilterdPlayers] = useState('');
  const [addPlayer, setAddPlayer] = useState(false);
  const [player1, setPlayer1] = useState('');
  const [player2, setPlayer2] = useState('');
  const [gameFound, setGameFound] = useState('');
  const [Data, setData] = useState('');

  let player1set = false;

  const filterOutChoosenPlayers = (players) => {
    if (player1set) {
      setFilterdPlayers(players.filter(e => e._id !== player1set));
      return
    }

    setFilterdPlayers(players);
  }

  const filterPlayers = (input) => {
    setPlayerSearch(input);
    const filtered = allPlayers.filter(e => {
      const tempName = e.name.toLowerCase();
      
      return tempName.indexOf(input.toLowerCase()) !== -1;
    });

    filterOutChoosenPlayers(filtered);
  }

  const setPlayer = (id) => {
    const player = allPlayers.find(e => e._id === id);

    if (!player1) {
      player1set = player._id;
      setPlayer1(player);
      setData([{player: player._id, score: 0}]);

    } else if (!player2) {
      setPlayer2(player);
      setData([...Data, {player: player._id, score: 0}]);
    }
    
    setAddPlayer(false);
    setPlayerSearch('');
    filterOutChoosenPlayers(allPlayers);
  }
  
  const PlayerList = () => {
    const items = filteredPlayers.map((e, i) => {
      return (
        <li key={i} onClick={() => setPlayer(e._id)}>{e.name}</li>
      )
    });

    return (
      <div className="popup" key={'playerList'}>
        <input placeholder='Zoeken...' className='search' type='text' onChange={((event) => filterPlayers(event.target.value))} value={playerSearch} />

        <ul className='playerList'>
          {items}
        </ul>
      </div>
    )
  }

  const changeScores = (playerIndex, score) => {
    const tempData = Data;
    Data[playerIndex].score = Number(score);

    setData([...tempData]);
  }

  const back = () => {
    navigate('/games');
    close();
  }

  const handleOverlayClick = (target) => {
    if (target.classList.contains('popup') || target.closest('.popup')) return;
    back();
  }

  const saveGame = async () => {
    if (gameFound) {
      gameFound.data = Data;
      const winnerObj = Data.reduce((prev, current) => (prev.score > current.score) ? prev : current);
      gameFound.winner = winnerObj.player;

      await db.updateGame(gameFound);

    } else {
      const winnerObj = Data.reduce((prev, current) => (prev.score > current.score) ? prev : current);

      const newGame = {
        data: Data,
        winner: winnerObj.player,
        date: new Date()
      }

      const dbObject = await db.setGame(newGame);
      player1.games.push(dbObject._id);
      player2.games.push(dbObject._id);
    }

    back();
  }

  const deleteGame = async () => {
     await db.deleteGame(gameFound);
     back();
  }


  useEffect(() => {
    const effect = async () => {
      const allGames = await db.getGames();
      const filterdGames = allGames.filter(e => e);
      const gameFound = filterdGames.find(e => e._id === hash.replace('#', ''));
      
      const allPlayers = await db.getPlayers();
      setAllPlayers(allPlayers);
      setFilterdPlayers(allPlayers);

      if (gameFound) {
        const { data } = gameFound;

        setGameFound(gameFound);
        setData(JSON.parse(JSON.stringify(data)));
        setPlayer1(allPlayers.find(e => e._id === data[0].player));
        setPlayer2(allPlayers.find(e => e._id === data[1].player));
      }
    }
 
    effect();
  }, [hash])



  
  return (
    <div className='overlay show' onClick={(event) => handleOverlayClick(event.target)}>
      {!addPlayer &&
        <div className='popup addGame'>

        {player1 && 
          <div className='row'>
            <p>{player1.name}</p>
            <input type="number" value={Data[0].score || 0} onChange={(event) => changeScores(0, event.target.value)}/>
          </div>
        }
      
        {player2 && 
          <div className='row'>
            <p>{player2.name}</p>
            <input type="number" value={Data[1].score || 0} onChange={(event) => changeScores(1, event.target.value)} />
          </div>
        }

        <div className='gameInfoFooter'>
          {(!player1 || !player2) && <button className='addGameBtn' onClick={() => setAddPlayer(true)}>Add speler</button>}

          {gameFound && 
            <button className='delete' onClick={() => deleteGame()}>Delete</button>
          }
          
          {player2 && 
            <button className='saveGame' onClick={() => saveGame()}>Save</button>
          }
        </div>
      </div>
      }

      { addPlayer &&
        [PlayerList()]
      }
    </div>
  )
}

export default GameInfo;