import { Outlet } from "react-router-dom";
import Nav from "../../components/nav"

const Main = () => {
  return (
    <>
      <Nav />
      <main className="container">
        <Outlet />
      </main>
    </>
  )
};

export default Main;