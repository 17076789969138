import axios from 'axios';
import {isThisWeek, isThisMonth, isThisYear} from 'date-fns';

const db = {
  players: [],
  games: [],

  getPlayers() {
    return new Promise(resolve => {
      if (this.players.length) {
        resolve(this.players);
        return;
      }

      axios.get('/api/db/getPlayers')
      .then(response => {
        this.players = response.data;
        resolve(response.data);
      })
      .catch(err => {
        console.error(err);
      });
    });
  },

  setPlayer(playerObject) {
    return new Promise(async resolve => {
      const players = await this.getPlayers();

      axios.post('/api/db/setPlayer', playerObject)
      .then(function (newPlayerID) {
        playerObject._id = newPlayerID.data
        players.push(playerObject);
        resolve(playerObject);
      })
      .catch(function (error) {
        console.log(error);
        resolve(false);
      });
    }); 
  },
  
  getGames() {
    return new Promise(resolve => {
      if (this.games.length) {
        resolve(this.games);
        return;
      }

      axios.get('/api/db/getGames')
      .then(response => {
        const data = response.data.reverse();
        this.games = data;
        resolve(response.data);
      })
      .catch(err => {
        console.error(err);
      });
    });
  },

  setGame(gameObj) {
    return new Promise(async resolve => {
      const games = await this.getGames();

      axios.post('/api/db/setGame', gameObj)
      .then(function (newPlayerID) {
        gameObj._id = newPlayerID.data;

        games.unshift(gameObj);
        resolve(gameObj);
      })
      .catch(function (error) {
        console.log(error);
        resolve(false);
      });
    });
  },

  updateGame(gameObj) {
    return new Promise(async resolve => {
      axios.post('/api/db/updateGame', gameObj)
      .then(function () {
        resolve();
      })
      .catch(function (error) {
        console.log(error);
        resolve(false);
      });
    });
  },

  deleteGame(game) {
    return new Promise(async resolve => {
      const games = await this.getGames();
      const gameIndex = games.findIndex(e => e && e._id === game._id);
      delete games[gameIndex];

      axios.post('/api/db/deleteGame', {game})
      .then(resp => {
        resolve(games);
      })
      .catch(err => {
        console.error(err);
      });
    });
  },

  getPlayerStats(id, date = false) {
    return new Promise(async resolve => {
      const allPlayers = await this.getPlayers();
      const player = allPlayers.find(e => e._id === id);

      let games = await this.getGames();

      if (date) {
        switch (date) {
          case 'week':
            games = games.filter(e => isThisWeek(new Date(e.date)));
            break;

          case 'month':
            games = games.filter(e => isThisMonth(new Date(e.date)));
            break;

          case 'year':
            games = games.filter(e => isThisYear(new Date(e.date)));
            break;
          
          case 'all':
            break;
        
          default:
            break;
        }
      }

      const gamesPlayed = games.filter(e => player.games.find(el => el === e._id));
      const winList = gamesPlayed.filter(e => e.winner === player._id);
      const wins = winList ? winList.length : 0;
      const loses = gamesPlayed.length - wins;
    
      resolve({wins, loses, games: gamesPlayed});
    });
  },

  getOpponents(id) {
    return new Promise(async resolve => {
      const players = await this.getPlayers();
      const player = players.find(e => e._id === id);
      const allGames = await this.getGames();
      const {games} = player;

      let opponants = [];
  
      games.forEach(gameID => {
        const game = allGames.find(e => e && e._id === gameID);
        if (!game) return;

        const opponantData = game.data.find(e => e.player !== id);
        const opponantObj = players.find(e => e._id === opponantData.player);
        opponants.push(opponantObj);
      });
  
      opponants = [...new Set(opponants)];
      resolve(opponants);
    });
  },
}

export default db;