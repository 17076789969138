import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from './pages/Main/Main';
import Spelers from './pages/Spelers/Spelers';
import SpelersOverzicht from './pages/SpelerOverzicht/SpelerOverzicht';
import Games from './pages/Games/Games';
import Ranglijsten from './pages/Ranglijsten/Ranglijsten';
import './reset.css';
import './App.css';

function App() {
  return (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<Navigate to="Spelers" />} />
        <Route path="spelers" element={<Spelers />} />
        <Route path="spelers/*" element={<SpelersOverzicht />} />
        <Route path="games" element={<Games />} />
        <Route path="ranglijsten" element={<Ranglijsten />} />

        <Route path="*" element={<Navigate to="Spelers" />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
