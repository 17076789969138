import { useEffect, useState } from 'react';
import {isThisWeek, isThisMonth, isThisYear} from 'date-fns';
import db from '../../utils/db';

const Ranglijsten = () => {
  const [allPlayers, setAllPlayers] = useState('');
  const [allGames, setAllGames] = useState('');
  const [filter, setFilter] = useState('all');
  const [result, setResult] = useState('');

  const getRatingDelta = (myRating, opponentRating, myGameResult) => {
    if ([0, 0.5, 1].indexOf(myGameResult) === -1) {
      return null;
    }
    
    var myChanceToWin = 1 / ( 1 + Math.pow(10, (opponentRating - myRating) / 400));

    return Math.round(32 * (myGameResult - myChanceToWin));
  }

  const getNewRating = (myRating, opponentRating, myGameResult) => {
    return myRating + getRatingDelta(myRating, opponentRating, myGameResult);
  }

  const rateGames = async (games, players, filterInput = false) => {
    if (!games.length) {
      setResult([]);
      return;
    }

    for (const player of players) {
      player.rank = 1000;
      player.played = false;

      const {wins, loses, games} = await db.getPlayerStats(player._id, filterInput);
      player.wins = wins;
      player.loses = loses;
      player.games = games;
    }

    games.forEach(game => {
      const {data} = game;

      const player1 = players.find(e => e._id === data[0].player);
      const player2 = players.find(e => e._id === data[1].player);
      
      player1.played = true;
      player2.played = true;

      const resPlayer1 = game.winner === player1._id ? 1 : 0;
      const resultPlayer1 = getNewRating(player1.rank, player2.rank, resPlayer1);

      const resPlayer2 = resPlayer1 === 1 ? 0 : 1;
      const resultPlayer2 = getNewRating(player2.rank, player1.rank, resPlayer2);

      player1.rank = resultPlayer1;
      player2.rank = resultPlayer2;
    });

    const temp = players.sort((a, b) => b.rank - a.rank);
    setResult([...temp]);
  }

  const filterGames = (input) => {
    setFilter(input);
    let filteredGames;
    
    switch (input) {
      case 'week':
        filteredGames = allGames.filter(e => isThisWeek(new Date(e.date)));
        break;

      case 'month':
        filteredGames = allGames.filter(e => isThisMonth(new Date(e.date)));
        break;

      case 'year':
        filteredGames = allGames.filter(e => isThisYear(new Date(e.date)));
        break;

      case 'all':
        filteredGames = allGames;
        break;
    
      default:
        break;
    }

    rateGames(filteredGames, allPlayers, input);
  }

  const PlayersList = () => {
    console.log('items', result);
    const items = result.filter(e => e.played).map((item, i) => {
      return(
        <>
        {item.played && 
        <li key={item._id}>
         <a>
          <div className='info'>
            <div className='score'>
              <span>{i+1}</span>
            </div>
            {item.name}
          </div>
  
          <div className='stats'>
            <div >Rank: <span>{item.rank}</span></div>
          </div>
         </a>
        </li>
      }
      </>
      )
    });
  
    return <ul className='borderList'>{items}</ul>
  }

  useEffect(() => {
    const effect = async () => {
      let games = await db.getGames();
      games = JSON.parse(JSON.stringify(games));
      
      let players = await db.getPlayers();
      players = JSON.parse(JSON.stringify(players));

      setAllGames(games);
      setAllPlayers(players);

      rateGames(games, players);
    }

    effect();
  }, [])


  return (
    <div className='rankList'>
       <header>
          <h1>Ranglijst</h1>

          <div className="utils">
            <button onClick={() => filterGames('week')} className={filter === 'week' ? 'active' : ''}>Week</button>
            <button onClick={() => filterGames('month')} className={filter === 'month' ? 'active' : ''}>Maand</button>
            <button onClick={() => filterGames('year')} className={filter === 'year' ? 'active' : ''}>Jaar</button>
            <button onClick={() => filterGames('all')} className={filter === 'all' ? 'active' : ''}>Alle</button>
          </div>
        </header>

      { result &&
        <PlayersList />
      }
    </div>
  )
}

export default Ranglijsten