import { NavLink } from "react-router-dom";

import spelerIcon from "../images/icons/spelers.svg";
import pingPongIcon from "../images/icons/ping-pong.svg";
import rankingIcon from "../images/icons/ranking.svg";

import spelerIconActive from "../images/icons/spelers-active.svg";
import pingPongIconActive from "../images/icons/ping-pong-active.svg";
import rankingIconActive from "../images/icons/ranking-active.svg";

const Nav = () => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/spelers" title="Spelers" className={({isActive}) => (isActive ? "active" : '')}>
            <img src={spelerIcon} alt="Spelers icon" />
            <img src={spelerIconActive} alt="Spelers icon" />
          </NavLink>
        </li>
        <li>
          <NavLink to="/games" title="Games" className={({isActive}) => (isActive ? "active" : '')}>
          <img src={pingPongIcon} alt="Ping-pong icon" />
          <img src={pingPongIconActive} alt="Ping-pong icon" />
          </NavLink>
        </li>
        <li>
          <NavLink to="/ranglijsten" title="Ranglijsten" className={({isActive}) => (isActive ? "active" : '')}>
          <img src={rankingIcon} alt="Ranking icon" />
          <img src={rankingIconActive} alt="Ranking icon" />
          </NavLink>
        </li>
      </ul>
    </nav>
  )
};

export default Nav;