import React, { useEffect, useState } from 'react';
import  Utils from '../../utils/utils';

import GameList from '../../components/GameList';
import GameOverzicht from '../../components/GameInfo';

const Games = () => {
  const [hash, setHash] = useState(() => window.location.hash);

  const closeGameInfo = () => {
    setHash('')
  }

  const filterPlayers = (input) => {
    const htmlObjs = Array.from(document.querySelectorAll('.games-vs'));

    const allowed = htmlObjs.filter(e => {
      const players = e.querySelectorAll('.player');
      const firstPlayer = players[0].children[1].textContent;
      const secondPlayer = players[1].children[1].textContent;

      return (
        firstPlayer.toLocaleUpperCase().indexOf(input.toLocaleUpperCase()) !== -1 ||
        secondPlayer.toLocaleUpperCase().indexOf(input.toLocaleUpperCase()) !== -1
      )
    });

    const notAllowed = htmlObjs.filter(e => {
      const players = e.querySelectorAll('.player');
      const firstPlayer = players[0].children[1].textContent;
      const secondPlayer = players[1].children[1].textContent;

      return (
        firstPlayer.toLocaleUpperCase().indexOf(input.toLocaleUpperCase()) === -1 &&
        secondPlayer.toLocaleUpperCase().indexOf(input.toLocaleUpperCase()) === -1
      )
    });

    allowed.forEach(e => e.closest('li').classList.remove('hide'));
    notAllowed.forEach(e => e.closest('li').classList.add('hide'));
  }

  useEffect(() => {
    const effect = async () => {
      window.addEventListener('hashchange', () => {
        if (window.location.hash !== hash) setHash(window.location.hash);

      });
    }

    effect();
  }, [hash]);

  return (
    <div>
      <header>
        <h1>Games</h1>
        <div className="utils">
          <input type="text" className="search" placeholder="Zoeken..." onKeyUp={Utils.debounce((event) => filterPlayers(event.target.value))} />
          <a className="add" href={'games#new'}>+</a>
        </div>
      </header>

      < GameList />

      {hash && <GameOverzicht close={closeGameInfo} />}
    </div>
  )
}

export default Games;