import {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import db from '../utils/db';

import crown from '../images/icons/crown.svg';


const GameList = () => {
  let [games, setGames] = useState('');
  let [players, setPlayers] = useState('');

  const location = useLocation();
  let id = location.pathname.match(/[^/]+\/(.+)$/) ? 
    location.pathname.match(/[^/]+\/(.+)$/)[1] : false;

  const Items = () => {
    const gamesArray = games.map((e, i) => {
      const winner = players.find(player => e.winner === player._id);
      const player1 = players.find(player => e.data[0].player === player._id);
      const player2 = players.find(player => e.data[1].player === player._id);
  
      return (<li key={e._id}>
        <a href={"/games#" + e._id}>
          <div className='winner'>
            <img src={crown} alt="crown icon"/>
            {winner.name}
          </div>
  
          <div className='games-vs'>
            <div className={`player ${e.winner === player1._id ? 'won' : 'lost'}`}>
              <span>{e.data[0].score}</span>
              <span>{player1.name}</span>
            </div>

            <div className={`player ${e.winner === player2._id ? 'won' : 'lost'}`}>
              <span>{e.data[1].score}</span>
              <span>{player2.name}</span>
            </div>
          </div>
        </a>
      </li>)
    });

    return(gamesArray)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (games) return;

      const players = await db.getPlayers();
      let gamesFound;

      if (id) {
        const gamesData = await db.getPlayerStats(id);
        gamesFound = gamesData.games;
      } else {
        gamesFound = await db.getGames();
      }

      setGames(gamesFound);
      setPlayers(players);
    }

    fetchData();
  },[games, id]);

  return (
    <ul className='borderList gameList'>
      {games && <Items />}
    </ul>
  )
}

export default GameList;